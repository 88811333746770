import { GetUploadRecordsResponse } from '@/shared/types';
/* eslint-disable */

const facebookAdsReplacements: Record<string, string> = {
  ts_until: 'endDate',
  account_id: 'account_id',
  campaign_name: 'campaign_name',
  ad_name: 'ad_name',
  ad_id: 'ad_id',
  campaign_id: 'campaign_id',
  impressions: 'impressions',
  adset_id: 'adset_id',
  adset_name: 'adset_name',
  clicks: 'clicks',
  spend: 'cost',
  reach: 'reach',
  frequency: 'frequency',
  objective: 'objective',
  region: 'region',
  date_start: 'start_date',
  date_stop: 'stop_date',
  country: 'country',
  optimization_goal: 'optimization_goal',
  'actions.Action.action_type.add_to_cart': 'add_to_cart',
  'actions.Action.action_type.purchase': 'purchase',
  'actions.Action.action_type.view_content': 'view_content',
  'actions.Action.action_type.initiate_checkout': 'initiate_checkout',
  'actions.Action.action_type.click_to_call_call_confirm':
    'click_to_call_call_confirm',
  'actions.Action.action_type.link_click': 'link_click',
  'action_values.ActionType.action_type.purchase': 'value_purchase',
  'action_values.ActionType.action_type.view_content': 'value_view_content',
  'action_values.ActionType.action_type.add_to_cart': 'value_add_to_cart',
  'action_values.ActionType.action_type.initiate_checkout':
    'value_initiate_checkout',
  'video_p100_watched_actions.VideoAction.action_type.video_view':
    'video_plays_25',
  'video_p25_watched_actions.VideoAction25.action_type.video_view':
    'video_plays_50',
  'video_p50_watched_actions.VideoAction50.action_type.video_view':
    'video_plays_75',
  'video_p75_watched_actions.VideoAction75.action_type.video_view':
    'video_plays_100',
  'video_play_actions.VideoPlayAction.action_type.video_view': 'video_plays',
  'actions.Action.action_type.post_engagement': 'post_engagement',
  'actions.Action.action_type.post_reaction': 'reactions',
  'actions.Action.action_type.comment': 'comments',
  'actions.Action.action_type.onsite_conversion.post_save': 'saves',
  'actions.Action.action_type.post': 'shares',
  'actions.Action.action_type.like': 'likes',
  'actions.Action.action_type.landing_page_view': 'landing_page_view',
  'actions.Action.action_type.video_view': '3_sec_video_plays',
  'actions.Action.action_type.lead': 'lead',
  ts_since: 'date',
  status: 'status',
  updated_time: 'update_date',
  publisher_platform: 'publisher_platform',
  device_platform: 'device_platform',
  age: 'age',
  gender: 'gender',
};

const instagramInsightsReplacements: Record<string, string> = {
  entity_id: 'account_id',
  start_date: 'date',
  'name.NameEnum.reach': 'reach',
  'name.NameEnum.total_interactions': 'total_interactions',
  'name.NameEnum.likes': 'likes',
  'name.NameEnum.comments': 'comments',
  'name.NameEnum.shares': 'shares',
  'name.NameEnum.saved': 'saved',
  'name.NameEnum.profile_links_taps': 'profile_links_taps',
  'name.NameEnum.impressions': 'impressions',
  'name.NameEnum.website_clicks': 'website_clicks',
  'name.NameEnum.accounts_engaged': 'accounts_engaged',
  'name.NameEnum.replies': 'replies',
  'name.NameEnum.follows_and_unfollows': 'new_followers',
  end_date: 'endDate',
  ts: 'date',
  'total_value.TotalValue.breakdowns.Breakdown.dimension_keys.country':
    'country',
  'total_value.TotalValue.breakdowns.Breakdown.dimension_keys.city': 'city',
  'total_value.TotalValue.breakdowns.Breakdown.dimension_keys.age': 'age',
  'total_value.TotalValue.breakdowns.Breakdown.dimension_keys.gender': 'gender',
  id: 'post_id',
  media_type: 'media_type',
  media_url: 'media_url',
  caption: 'caption',
  timestamp: 'created_at',
  thumbnail_url: 'thumbnail_url',
  'insights.InsightsData.data.Insight.name.NameEnum.replies': 'replies',
  'insights.InsightsData.data.Insight.name.NameEnum.video_views': 'video_views',
  'insights.InsightsData.data.Insight.name.NameEnum.reach': 'reach',
  'insights.InsightsData.data.Insight.name.NameEnum.likes': 'likes',
  'insights.InsightsData.data.Insight.name.NameEnum.impressions': 'impressions',
  'insights.InsightsData.data.Insight.name.NameEnum.shares': 'shares',
  'insights.InsightsData.data.Insight.name.NameEnum.comments': 'comments',
  'insights.InsightsData.data.Insight.name.NameEnum.total_interactions':
    'total_interactions',
  'insights.InsightsData.data.Insight.name.NameEnum.saved': 'saved',
  'insights.InsightsData.data.Insight.name.NameEnum.ig_reels_avg_watch_time':
    'avg_watch_time',
  'insights.InsightsData.data.Insight.name.NameEnum.ig_reels_video_view_total_tim':
    'total_watch_time',
};

const fbInsightsReplacements: Record<string, string> = {
  entity_id: 'account_id',
  'values.Value.end_time': 'date',
  'name.NameEnum.page_views_total': 'page_views',
  'name.NameEnum.page_follows': 'followers_total',
  'name.NameEnum.page_fans': 'page_likes_total',
  'name.NameEnum.page_impressions_unique': 'reach',
  'name.NameEnum.page_impressions': 'impressions',
  'name.NameEnum.page_post_engagements': 'post_engagements',
  'name.NameEnum.page_video_views': 'video_views',
  'name.NameEnum.page_video_view_time': 'page_video_view_time',
  'page_fans_locale.LocaleEntry.key': 'language',
  'page_fans_city.CityEntry.key': 'city',
  'page_fans_country.CountryEntry.key': 'country',
  ts: 'date',
  id: 'id',
  created_time: 'created_at',
  source_type: 'content_type',
  'video_insights.VideoInsights.post_video_social_actions.SocialActions.SHARE':
    'shares',
  'video_insights.VideoInsights.post_video_social_actions.SocialActions.COMMENT':
    'comments',
  'video_insights.VideoInsights.post_video_likes_by_reaction_type.LikesByReaction.REACTION_LIKE':
    'likes',
  'video_insights.VideoInsights.post_impressions_unique': 'reach',
  'video_insights.VideoInsights.fb_reels_total_plays': 'video_views',
  'video_insights.VideoInsights.post_video_view_time': 'total_watch_time',
  'video_insights.VideoInsights.post_video_avg_time_watched': 'avg_watch_time',
  picture: 'thumbnail',
  permalink_url: 'url',
  shares_count: 'shares',
  comments_count: 'comments',
  likes_count: 'likes',
  post_impressions: 'impressions',
  post_impressions_unique: 'reach',
  post_video_views: 'video_views',
  post_video_view_time: 'total_watch_time',
  post_video_avg_time_watched: 'avg_watch_time',
};

const googleAdsReplacements: Record<string, string> = {
  ts_since: 'date',
  ts_until: 'endDate',
  'customer.Customer.id': 'account_id',
  'customer.Customer.descriptive_name': 'account_name',
  'customer.Customer.currency_code': 'account_currency',
  'customer.Customer.time_zone': 'account_time_zone',
  campaign_name: 'campaign_name',
  'campaign.Campaign.name': 'campaign_name',
  'campaign.Campaign.id': 'campaign_id',
  'ad_group.AdGroup.id': 'ad_group_id',
  'ad_group.AdGroup.name': 'ad_group_name',
  'metrics.Metrics.interaction_event_types': 'interaction_event_types',
  'metrics.Metrics.clicks': 'clicks',
  'metrics.Metrics.conversions_value': 'conversions_value',
  'metrics.Metrics.conversions': 'conversions',
  'metrics.Metrics.cost_micros': 'cost',
  'metrics.Metrics.impressions': 'impressions',
  'ad_group_ad.AdGroupAd.ad.id': 'ad_name',
  'ad_group_ad.AdGroupAd.ad.name': 'ad_id',
  'segments.Segments.device': 'device_type',
  'segments.Segments.ad_network_type': 'ad_network_type',
  'segments.Segments.slot': 'position_ad',
  'ad_group.AdGroup.status': 'ad_group_status',
  'ad_group.AdGroup.type_': 'ad_group_type',
  'ad_group_criterion.AdGroupCriterion.age_range.AgeRange.type_': 'age_range',
  'metrics.Metrics.search_budget_lost_impression_share':
    'content_budget_lost_impression',
  'metrics.Metrics.search_impression_share':
    'possible_content_impression_share',
  'metrics.Metrics.search_rank_lost_impression_share':
    'content_rank_lost_impression',
  'metrics.Metrics.content_budget_lost_impression_share':
    'search_budget_lost_impression',
  'metrics.Metrics.content_impression_share': 'possible_search_impressions',
  'metrics.Metrics.content_rank_lost_impression_share':
    'search_rank_lost_impression',
  'campaign.Campaign.status': 'campaign_status',
  'campaign.Campaign.advertising_channel_type': 'campaign_advertising_type',
  'campaign.Campaign.bidding_strategy_type': 'campaign_strategy_type',
  'campaign.Campaign.campaign_budget': 'campaign_budget',
  'campaign.Campaign.start_date': 'campaign_start_date',
  'ad_group_criterion.AdGroupCriterion.gender.Gender.type_': 'gender',
  'segments.Segments.geo_target_most_specific_location':
    'cite, region, country',
  'ad_group_criterion.AdGroupCriterion.keyword.Keyword.text': 'keyword',
  'ad_group_criterion.AdGroupCriterion.keyword.Keyword.KeywordInfo.text':
    'keyword',
  'segments.Segments.search_term_match_type': 'search_term_match_type',
  'segments.Segments.keyword': 'search_query',
};

const googleAnalyticsReplacements: Record<string, string> = {
  ts_since: 'date',
  ts_until: 'endDate',
  sessionDefaultChannelGroup: 'sessionDefaultChannelGroup',
  sessionSource: 'sessionSource',
  sessionMedium: 'sessionMedium',
  sessionCampaignName: 'sessionCampaignName',
  sessionSourcePlatform: 'sessionSourcePlatform',
  deviceCategory: 'deviceCategory',
  deviceModel: 'deviceModel',
  active1DayUsers: 'active1DayUsers',
  active7DayUsers: 'active7DayUsers',
  active28DayUsers: 'active28DayUsers',
  activeUsers: 'activeUsers',
  addToCarts: 'addToCarts',
  averageSessionDuration: 'averageSessionDuration',
  checkouts: 'checkouts',
  conversions: 'conversions',
  ecommercePurchases: 'ecommercePurchases',
  engagedSessions: 'engagedSessions',
  eventCount: 'eventCount',
  newUsers: 'newUsers',
  screenPageViews: 'screenPageViews',
  sessions: 'sessions',
  totalPurchasers: 'totalPurchasers',
  totalRevenue: 'totalRevenue',
  transactions: 'transactions',
  property_id: 'accountId',
  brandingInterest: 'brandingInterest',
  userAgeBracket: 'userAgeBracket',
  userGender: 'userGender',
  browser: 'browser',
  language: 'language',
  operatingSystem: 'operatingSystem',
  platform: 'platform',
  country: 'country',
  region: 'region',
  city: 'city',
  newVsReturning: 'newVsReturning',
  itemName: 'itemName',
  itemCategory: 'itemCategory',
  itemsAddedToCart: 'itemsAddedToCart',
  itemsCheckedOut: 'itemsCheckedOut',
  itemsPurchased: 'itemsPurchased',
  itemRevenue: 'itemRevenue',
  eventName: 'eventName',
  isConversionEvent: 'isConversionEvent',
  continent: 'continent',
  landingPage: 'landingPage',
  pageTitle: 'pageTitle',
  pagePath: 'pagePath',
};

const tiktokAdsReplacements: Record<string, string> = {
  'dimensions.Dimensions.platform': 'platform',
  'dimensions.Dimensions.ad_id': 'ad_id',
  'metrics.Metrics.ad_name': 'ad_name',
  'metrics.Metrics.advertiser_name': 'account_name',
  'metrics.Metrics.campaign_id': 'campaign_id',
  'metrics.Metrics.conversion': 'conversion',
  'metrics.Metrics.video_views_p25': 'video_views_p25',
  'metrics.Metrics.video_views_p50': 'video_views_p50',
  'metrics.Metrics.profile_visits': 'profile_visits',
  'metrics.Metrics.video_views_p75': 'video_views_p75',
  'metrics.Metrics.adgroup_id': 'adgroup_id',
  'metrics.Metrics.average_video_play': 'avg_video_play',
  'metrics.Metrics.impressions': 'impressions',
  'metrics.Metrics.result': 'result',
  'metrics.Metrics.video_watched_6s': 'video_views_6s',
  'metrics.Metrics.advertiser_id': 'account_id',
  'metrics.Metrics.video_play_actions': 'video_views',
  'metrics.Metrics.video_views_p100': 'video_views_p100',
  'metrics.Metrics.follows': 'follows',
  'metrics.Metrics.adgroup_name': 'adgroup_name',
  'metrics.Metrics.shares': 'shares',
  'metrics.Metrics.likes': 'likes',
  'metrics.Metrics.spend': 'cost',
  'metrics.Metrics.ad_id': 'ad_id',
  'metrics.Metrics.engaged_view': 'engaged_view',
  'metrics.Metrics.clicks': 'clicks',
  'metrics.Metrics.campaign_name': 'campaign_name',
  'metrics.Metrics.average_video_play_per_user': 'avg_video_play_per_user',
  'metrics.Metrics.video_watched_2s': 'video_views_2s',
  'metrics.Metrics.currency': 'currency',
  'metrics.Metrics.comments': 'comments',
  'result.Result.client_name': 'account_name',
  'result.Result.campaign_name': 'campaign_name',
  'result.Result.start_date': 'date',
  'result.Result.end_date': 'endDate',
  'dimensions.Dimensions.country_code': 'country',
  'metrics.Metrics.engagements': 'engagements',
  'metrics.Metrics.engaged_view_15s': 'engaged_view_15s',
  'metrics.Metrics.secondary_goal_result': 'secondary_goal_result',
  'metrics.Metrics.frequency': 'frequency',
  'metrics.Metrics.reach': 'reach',
  'metrics.Metrics.total_onsite_shopping_value': 'revenue',
  'dimensions.Dimensions.gender': 'gender',
  'dimensions.Dimensions.age': 'age',
  'dimensions.Dimensions.advertiser_id': 'account_id',
};

const tiktokOrganicReplacements: Record<string, string> = {
  'videos.VideoMetrics.full_video_watched_rate': 'full_video_watched_rate',
  'videos.VideoMetrics.likes': 'likes',
  'videos.VideoMetrics.share_url': 'url',
  'videos.VideoMetrics.total_time_watched': 'total_time_watched',
  'videos.VideoMetrics.thumbnail_url': 'thumbnail_url',
  'videos.VideoMetrics.video_views': 'video_views',
  'videos.VideoMetrics.video_duration': 'video_duration',
  'videos.VideoMetrics.average_time_watched': 'average_time_watched',
  'videos.VideoMetrics.comments': 'comments',
  'videos.VideoMetrics.shares': 'shares',
  'videos.VideoMetrics.reach': 'reach',
  'videos.VideoMetrics.item_id': 'video_id',
  'videos.VideoMetrics.create_time': 'created_at',
  ts: 'date',
  client_name: 'account_name',
  'metrics.Metrics.video_views': 'video_views_day',
  'metrics.Metrics.followers_count': 'followers_day',
  'metrics.Metrics.shares': 'shares',
  'metrics.Metrics.profile_views': 'account_views',
  'metrics.Metrics.comments': 'comments',
  'metrics.Metrics.likes': 'likes_day',
  username: 'account_name',
  followers_count: 'followers_total',
  'audience_genders.GenderPercentage.gender': 'gender',
  'audience_genders.GenderPercentage.percentage': 'followers',
  'audience_countries.CountryPercentage.country': 'country',
  'audience_countries.CountryPercentage.percentage': 'followers',
};
/* eslint-enable */

export function transformGetUploadRecordsResponse(
  resp: GetUploadRecordsResponse,
  sourceName: string
) {
  const { data } = resp;

  const filteredRecords = data.filter(
    (item) =>
      item.attributes.data_upload_record_status === 'fail' ||
      (item.attributes.data_upload_record_status === 'success' &&
        item.attributes.missed_metrics !== null)
  );

  const transformedRecords = filteredRecords.map((record) => ({
    id: record.id,
    type: 'data_upload_record',
    missedMetrics: record.attributes.missed_metrics
      ? Object.values(
          record.attributes.missed_metrics.reduce((acc, metric) => {
            const [title, ...rest] = metric.split('.');
            if (!acc[title]) {
              acc[title] = { title, metrics: [] };
            }
            acc[title].metrics.push(rest.join('.'));
            return acc;
          }, {} as Record<string, { title: string; metrics: string[] }>)
        )
      : null,
    status: record.attributes.data_upload_record_status,
    createdAt: record.attributes.created_at,
    updatedAt: record.attributes.updated_at,
  }));

  if (sourceName) {
    let mappingTable: Record<string, string> = {};
    switch (sourceName) {
      case 'tiktok_organic':
        mappingTable = tiktokOrganicReplacements;
        break;

      case 'tiktok_ads':
        mappingTable = tiktokAdsReplacements;
        break;

      case 'google_analytics':
        mappingTable = googleAnalyticsReplacements;
        break;

      case 'google_ads':
        mappingTable = googleAdsReplacements;
        break;

      case 'facebook':
        mappingTable = fbInsightsReplacements;
        break;

      case 'instagram':
        mappingTable = instagramInsightsReplacements;
        break;

      case 'fb_inst_ads':
        mappingTable = facebookAdsReplacements;
        break;
    }

    transformedRecords.forEach((record) => {
      if (record.missedMetrics) {
        record.missedMetrics = record.missedMetrics.map(
          ({ title, metrics }) => ({
            title,
            metrics: metrics.map((metric) => mappingTable[metric] || metric),
          })
        );
      }
    });
  }

  return { data: transformedRecords };
}
